// Packages
import _ from 'lodash';
import getSchema from 'schemas';
import { DeepPartial } from 'redux';
import { useHistory } from 'react-router-dom';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { BaseForm, BaseFormProps, BaseFormState, Bridge } from 'uniforms';

// Components
import Card from 'shared/components/Card';
import BackBtn from 'shared/components/Buttons/Back';
import { Box, Grid, Typography } from '@mui/material';
import Button from 'shared/components/Buttons/Primary';
import { AutoField, AutoForm, ErrorField } from 'uniforms-mui';

// Utilities
import useStyles from './styles';
import { useFormStyles } from 'shared/assets/styles';
import { getDataLanguage } from 'redux/services/ui/slice';
import { getLanguages } from 'redux/services/config/slice';
import { NotificationProps } from 'shared/types/Notification';
import { useAppSelector, useAppThunkDispatch } from 'app/store';
import { createNotificationAction, modifyNotificationAction } from 'redux/notifications/thunks';

// Component

const Form = () => {
  // Redux
  const dispatchThunk = useAppThunkDispatch();

  const languages = useAppSelector(getLanguages);
  const dataLang = useAppSelector(getDataLanguage);

  // Statics
  const history = useHistory();
  const styles = useStyles();
  const formStyles = useFormStyles();
  const classes = { ...styles, ...formStyles };

  const form = useRef<BaseForm<
    DeepPartial<unknown>,
    BaseFormProps<DeepPartial<unknown>>,
    BaseFormState<DeepPartial<unknown>>
  > | null>(null);

  const notification = history.location.state as NotificationProps;

  const [schema, setSchema] = useState<Bridge>();
  const [model, setModel] = useState<NotificationProps | undefined>();

  const modify = !_.isEmpty(notification);

  // Callbacks
  const setSchemaDef = useCallback(() => {
    try {
      setSchema(getSchema('notificationSchema', {}, { languages, defaultLang: dataLang }, {}));
    } catch (err) {
      console.error('Error in [Notification Form - setSchemaDef] :: ', err);
    }
  }, [dataLang, languages]);

  const handleSubmit = useCallback(
    async (model) => {
      const { _id, language, title, message } = model;

      const payload: NotificationProps = {
        _id,
        language,
        title,
        message
      };

      const response = await dispatchThunk(
        modify ? modifyNotificationAction(payload) : createNotificationAction(payload)
      );

      if (response.payload.success) history?.goBack();
    },
    [dispatchThunk, history, modify]
  );

  const handleModelChange = useCallback((key: string, value: any) => {
    setModel((prevModel) => _.merge({}, prevModel, { [key]: value }));
  }, []);

  // Effects
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setSchemaDef();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    setModel({
      ...notification,
      language:
        !notification?.language && _.isEqual(dataLang, 'all')
          ? _.find(languages, { value: 'en' })?._id
          : notification?.language?._id
    });
  }, [notification, dataLang, languages]);

  // Renderers
  return (
    <>
      <Box className={classes.header}>
        <BackBtn />
        <Typography variant="h5">{modify ? 'Modify' : 'New'} Notification</Typography>
      </Box>

      <Card>
        {schema ? (
          <AutoForm
            ref={form}
            placeholder
            model={model}
            schema={schema}
            onSubmit={handleSubmit}
            onChange={handleModelChange}>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <AutoField name="language" />
                <ErrorField name="language" />

                <AutoField name="title" />
                <ErrorField name="title" />

                <AutoField name="message" />
                <ErrorField name="message" />

                <Box className={classes.footer}>
                  <Button text="Submit" onClick={() => form.current?.submit()} />
                </Box>
              </Grid>
            </Grid>
          </AutoForm>
        ) : null}
      </Card>
    </>
  );
};

export default Form;
