// Theme
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

// Styles

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.colors.white
  },
  infoBox: {
    padding: '100px 36px',
    [theme.breakpoints.down('md')]: {
      padding: '24px 16px'
    }
  },
  infoContainer: {
    maxWidth: '60%',
    margin: '0 auto',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100%',
      margin: '0px 16px'
    }
  },
  box: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  appIcon: {
    width: 64,
    height: 64,
    marginLeft: -10,
    marginBottom: 48
  },
  mockup1: {
    width: '90%',
    height: '100vh',
    objectFit: 'cover',
    [theme.breakpoints.down('lg')]: {
      height: 'auto'
    }
  },
  storeButtons: {
    gap: 16,
    marginTop: 28,
    display: 'flex'
  },
  storeButton: {
    width: 160
  },
  divider: {
    margin: '32px 0'
  }
}));

export default useStyles;
