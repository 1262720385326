// Packages
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import { stringify } from 'qs';
import 'moment-timezone';

// Utilities
import { store } from './store';
import { logout } from 'shared/utils';
import { authActions } from 'redux/services/auth/slice';
// import { logout } from 'shared/utils';

// import {ROUTES} from 'shared/constants/routes';

// import i18n from "./i18n";

axios.interceptors.request.use((req) => {
  // Set common config
  req.baseURL = process.env.REACT_APP_BACKEND_URL;
  req.headers.Accept = 'application/json';
  req.headers['timeZone'] = moment.tz.guess();
  req.withCredentials = true;

  req.paramsSerializer = {
    serialize: (params) => stringify(params, { skipNulls: true, allowDots: true })
  };

  // Set auth token if applicable
  const storeState = store.getState();
  const authenticated = storeState?.auth.authenticated;
  const accessToken = storeState?.auth.token;
  req.headers.Authorization = authenticated ? `Bearer ${accessToken}` : process.env.REACT_APP_TOKEN;

  // Set language
  // req.headers.language = i18n.language || process.env.REACT_APP_DEFAULT_LANGUAGE;

  return req;
});

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.debug('API ERROR ::: ', error);

    if (error?.response && [401, 403].includes(error.response.status)) {
      const response = await axios.get('/auth/refresh-token', { withCredentials: true });

      if (response.status === 200) {
        const { accessToken } = response.data;
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        store.dispatch(authActions.update({ token: accessToken }));

        return axios(error.config);
      }
    }

    if (error?.response && _.isEqual(error?.response?.status, 500) && error.response.data.jwtExpired) {
      logout('Session expired, forced logout');
    }

    throw error;
  }
);
