// Theme
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

// Styles

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    top: '50%',
    left: '50%',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translate(-50%, -50%)'
  },
  modalContent: {
    minWidth: 400,
    outline: 'none',
    maxWidth: '90%',
    maxHeight: '90%',
    borderRadius: 16,
    overflowY: 'auto',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    backgroundColor: theme.palette.background.paper
  },
  imageContainer: {
    display: 'flex',
    marginInlineEnd: 16,
    marginBottom: 16,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  previewImage: {
    width: 100,
    height: 'auto',
    aspectRatio: '1/1',
    objectFit: 'cover',
    borderRadius: 8,
    marginBottom: 8
  },
  selectBtn: {
    padding: 0,
    minHeight: 'auto'
  },
  progressContainer: {
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  pagination: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'center'
  }
}));

export default useStyles;
