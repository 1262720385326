// Packages
import { toast } from 'react-toastify';
import { createAsyncThunk } from '@reduxjs/toolkit';

// Utilities
import {
  deleteNotification,
  modifyNotification,
  createNotification,
  getNotification,
  getNotifications,
  sendPushNotification
} from './api';

// Interfaces
import { NotificationProps } from 'shared/types/Notification';

// Actions

export const getNotificationsAction = createAsyncThunk(
  'notification/getNotifications',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getNotifications();

      return response.data.result;
    } catch (error: any) {
      toast.error(error.response?.data?.error);

      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const getNotificationAction = createAsyncThunk('notification/get', async (_id: string, { rejectWithValue }) => {
  try {
    const response = await getNotification(_id);

    return response.data.result;
  } catch (error: any) {
    toast.error(error.response?.data?.error);

    return rejectWithValue(error.response?.data?.error);
  }
});

export const createNotificationAction = createAsyncThunk(
  'notification/create',
  async (payload: NotificationProps, { rejectWithValue }) => {
    try {
      const response = await createNotification(payload);

      if (response.data.success) toast.success(response.data.message);

      return response.data;
    } catch (error: any) {
      toast.error(error.response?.data?.error);

      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const modifyNotificationAction = createAsyncThunk(
  'notification/update',
  async (payload: NotificationProps, { rejectWithValue }) => {
    try {
      const response = await modifyNotification(payload);

      if (response.data.success) toast.success(response.data.message);

      return response.data;
    } catch (error: any) {
      toast.error(error.response?.data?.error);

      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const deleteNotificationAction = createAsyncThunk(
  'notification/delete',
  async (_id: number, { rejectWithValue }) => {
    try {
      const response = await deleteNotification(_id);

      if (response.data.success) toast.success(response.data.message);

      return response.data;
    } catch (error: any) {
      toast.error(error.response?.data?.error);

      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const sendPushNotificationAction = createAsyncThunk(
  'user/sendPushNotification',
  async (payload: NotificationProps, { rejectWithValue }) => {
    try {
      const response = await sendPushNotification(payload);

      console.debug('[sendPushNotificationAction - response] :: ', response);
      if (response.data.success) toast.success(response.data.message);

      return response.data.result;
    } catch (error: any) {
      toast.error(error.response?.data?.message);

      return rejectWithValue(error.response?.data?.message);
    }
  }
);
