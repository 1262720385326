// Packages
import SimpleSchema from 'simpl-schema';

// Utilities
import SchemaHelpers from '../common';

// Schema
export const resetPasswordSchema = (classes = {}, { show }, { toggle }) => {
  try {
    return new SimpleSchema({
      password: SchemaHelpers.password(
        classes,
        { label: 'New Password', optional: true },
        { show: show?.new, name: 'password', toggle }
      ),
      confPassword: SchemaHelpers.password(
        classes,
        { label: 'Confirm New Password', optional: true },
        { show: show?.confirmation, name: 'confPassword', toggle }
      )
    });
  } catch (err) {
    console.debug('SCHEMA ERROR ::: ', err);
  }
};
