// Packages
import getSchema from 'schemas';
import { DeepPartial } from 'redux';
import { toast } from 'react-toastify';
import { BaseForm, BaseFormProps, BaseFormState } from 'uniforms';
import { SimpleSchema2Bridge } from 'uniforms-bridge-simple-schema-2';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

// Components
import { Box, Typography } from '@mui/material';
import { AutoField, AutoForm, ErrorField } from 'uniforms-mui';
import SubmitField from 'shared/components/Controls/SubmitField';

// Utilities
import useStyles from './styles';
import { useAppSelector } from 'app/store';
import { resetPassword } from 'redux/services/auth/api';
import { getAuthLoading } from 'redux/services/auth/slice';
import { useCommonStyles, useFormStyles } from 'shared/assets/styles';
// Component

const ResetPassword = () => {
  // Redux
  const isAuthLoading = useAppSelector(getAuthLoading);

  // Statics
  const form = useRef<BaseForm<
    DeepPartial<unknown>,
    BaseFormProps<DeepPartial<unknown>>,
    BaseFormState<DeepPartial<unknown>>
  > | null>(null);

  const styles = useStyles();
  const formStyles = useFormStyles();
  const commonStyles = useCommonStyles();
  const classes = useMemo(() => ({ ...styles, ...formStyles, ...commonStyles }), [commonStyles, formStyles, styles]);

  const [schema, setSchema] = useState<SimpleSchema2Bridge>();
  const [success, setSuccess] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState({ current: false, new: false, confirmation: false });

  const token = new URLSearchParams(window.location.search).get('token');

  // Callbacks
  const toggle = useCallback((name) => {
    switch (name) {
      case 'password':
        setShowPassword((cur) => ({ ...cur, new: !cur.new }));
        break;

      case 'confPassword':
        setShowPassword((cur) => ({ ...cur, confirmation: !cur.confirmation }));
        break;

      default:
        setShowPassword({ current: false, new: false, confirmation: false });
        break;
    }
  }, []);

  const setSchemaDef = useCallback(() => {
    try {
      setSchema(getSchema('resetPasswordSchema', classes, { show: showPassword }, { toggle }));
    } catch (err) {
      console.error('Error in [ResetPasswordForm - setSchemaDef] :: ', err);
    }
  }, [classes, showPassword, toggle]);

  const handleSubmit = useCallback(
    async (model) => {
      const { password, confPassword } = model;

      console.debug('[handleSubmit] :: ', { model, token });

      if (password !== confPassword) {
        toast.error('Passwords do not match');

        return;
      }

      const response = await resetPassword({ token, password });
      console.debug('response.data', response.data);

      if (response.data.success) setSuccess(true);
    },
    [token]
  );

  // Effects
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setSchemaDef();
  }, [showPassword]);
  /* eslint-enable react-hooks/exhaustive-deps */

  // Renderers
  const renderHeader = useMemo(
    () => (
      <>
        <Typography align="center" marginBottom={3}>
          Reset Your Password
        </Typography>
        <Typography variant="body2" marginBottom={1} textAlign="center">
          Create a new password and confirm to reset your account access.
        </Typography>
      </>
    ),
    []
  );

  const renderSuccess = useMemo(
    () => (
      <>
        <Typography textAlign="center" marginBottom={2}>
          Password Reset Successful
        </Typography>
        <Typography variant="body2" textAlign="center">
          Your password has been successfully reset. You can now login with your new password.
        </Typography>
      </>
    ),
    []
  );

  const renderForm = useMemo(
    () =>
      schema ? (
        <AutoForm ref={form} schema={schema} onSubmit={handleSubmit} placeholder>
          <AutoField name="password" />
          <ErrorField name="password" />

          <AutoField name="confPassword" />
          <ErrorField name="confPassword" />

          <Box className={classes.footer}>
            <SubmitField title="Reset Password" loading={isAuthLoading} />
          </Box>
        </AutoForm>
      ) : null,
    [schema, handleSubmit, classes.footer, isAuthLoading]
  );

  return (
    <Box className={classes.container}>
      <Box className={classes.card}>
        <img alt="appIcon" className={classes.appIcon} src={require('../../shared/assets/images/app-icon.png')} />

        {success ? (
          renderSuccess
        ) : (
          <>
            {renderHeader}
            {renderForm}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ResetPassword;
