// Packages
import _ from 'lodash';
import SimpleSchema from 'simpl-schema';

// Utilities
import SchemaHelpers from '../common';

// Schema

export const notificationSchema = (classes = {}, { languages, defaultLang }) => {
  try {
    const config = {
      languages: _.map(languages, (lang) => ({ label: lang.label, value: lang._id }))
    };

    return new SimpleSchema({
      language: SchemaHelpers.select(
        classes,
        {
          label: 'Language',
          optional: false,
          defaultValue: _.find(languages, { value: defaultLang })?._id
        },
        {
          options: config.languages
        }
      ),
      title: SchemaHelpers.text(
        classes,
        {
          label: 'Title',
          optional: false
        },
        {}
      ),
      message: SchemaHelpers.text(
        classes,
        { label: 'Message', optional: false },
        {
          multiline: true,
          rows: 7
        }
      )
    });
  } catch (err) {
    console.debug('SCHEMA ERROR ::: ', err);
  }
};
