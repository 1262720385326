// Packages
import axios from 'axios';

// Utilities
import { TABLE_PAGE_SIZE } from 'shared/constants/variables';

// Interfaces
import { ApiRequestProps } from 'shared/types/ApiRequest';

// Calls

export const getDestinations = ({ page, lang }: ApiRequestProps) =>
  axios.get(`destination/list?items=${TABLE_PAGE_SIZE}&page=${page}`, { headers: { 'accept-language': lang } });

export const getDestination = (_id: string) => axios.get(`destination/read/${_id}`);

export const modifyDestination = (payload: FormData) => {
  return axios.patch(`destination/update/${payload.get('_id')}`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const createDestination = (payload: FormData) => {
  return axios.post('destination/create', payload, { headers: { 'Content-Type': 'multipart/form-data' } });
};

export const deleteDestination = (_id: number) => axios.delete(`destination/delete/${_id}`, {});
