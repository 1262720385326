// Packages
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import React, { useCallback, useEffect } from 'react';

// Components
import Card from 'shared/components/Card';
import DataTable from 'shared/components/DataTable';
import Button from 'shared/components/Buttons/Primary';
import { Box, IconButton, Typography } from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

// Utilities
import useStyles from './styles';
import { selectNotifications } from 'redux/notifications/slice';
import { useAppSelector, useAppThunkDispatch } from 'app/store';
import {
  deleteNotificationAction,
  getNotificationsAction,
  sendPushNotificationAction
} from 'redux/notifications/thunks';

// Component

const Table = () => {
  // Redux
  const dispatchThunk = useAppThunkDispatch();

  const notifications = useAppSelector(selectNotifications);

  // Statics
  const classes = useStyles();
  const history = useHistory();

  // Callbacks
  const fetchNotifications = useCallback(() => {
    dispatchThunk(getNotificationsAction());
  }, [dispatchThunk]);

  const handleDelete = useCallback(
    async ({ closeToast, _id }) => {
      closeToast();
      const response = await dispatchThunk(deleteNotificationAction(_id));
      if (response.payload.success) fetchNotifications();
    },
    [dispatchThunk, fetchNotifications]
  );

  const CloseButton = useCallback(
    (props) => <Button onClick={() => handleDelete(props)} sx={{ padding: '4px 12px' }} text="Yes" />,
    [handleDelete]
  );

  const handleDeleteNotification = useCallback(
    ({ _id }) => {
      toast('Would you like to delete this notification?', {
        autoClose: false,
        closeOnClick: true,
        position: 'top-center',
        style: { alignItems: 'center', width: 400 },
        closeButton: (props) => <CloseButton _id={_id} {...props} />
      });
    },
    [CloseButton]
  );

  const handleRowEdit = useCallback((row) => history.push(`/notifications/form`, row), [history]);

  const handleSendNotification = useCallback(
    async (data) => {
      await dispatchThunk(sendPushNotificationAction(data));
    },
    [dispatchThunk]
  );

  const renderRowActions = useCallback(
    ({ data }) => (
      <Box className={classes.rowActionBtns}>
        <Button text="Send" size="small" onClick={() => handleSendNotification(data)} />
        <IconButton className={classes.actionBtn} onClick={() => handleDeleteNotification(data)}>
          <DeleteRoundedIcon fontSize="small" color="error" />
        </IconButton>
        <IconButton className={classes.actionBtn} onClick={() => handleRowEdit(data)}>
          <EditRoundedIcon fontSize="small" color="primary" />
        </IconButton>
      </Box>
    ),
    [classes.actionBtn, classes.rowActionBtns, handleDeleteNotification, handleRowEdit, handleSendNotification]
  );

  // Renderers Vars
  const getTableHeaders = useCallback(
    () => [
      { field: 'language.label', headerName: 'Language', flex: 0.5, minWidth: 100 },
      { field: 'title', headerName: 'Title', flex: 0.6, minWidth: 300 },
      { field: 'message', headerName: 'Message', flex: 1, minWidth: 450, resizable: false },
      {
        field: 'actions',
        headerName: 'Actions',
        cellRenderer: renderRowActions,
        flex: 0.4,
        width: 200,
        pinned: 'right',
        lockPosition: 'right',
        resizable: false
      }
    ],
    [renderRowActions]
  );

  // Effects
  useEffect(() => {
    fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Renderers Vars
  const data = notifications,
    columns = getTableHeaders();

  const tableProps = {
    data,
    columns,
    pageSize: 25,
    options: {
      hasLanguages: true
    }
  };

  // Renderers
  return (
    <>
      <Box className={classes.header}>
        <Typography variant="h5">Notifications</Typography>
        <Button text="Add Notification" onClick={() => history.push('/notifications/form')} />
      </Box>

      <Card>
        <Box sx={{ flexGrow: 1 }}>
          <DataTable tableProps={tableProps} />
        </Box>
      </Card>
    </>
  );
};

export default Table;
