// Packages
import axios from 'axios';

// Utilities
import { NotificationProps } from 'shared/types/Notification';

// Calls

export const getNotifications = () => axios.get('notification/list');

export const getNotification = (_id: string) => axios.get(`notification/read/${_id}`);

export const modifyNotification = (payload: NotificationProps) =>
  axios.patch(`notification/update/${payload._id}`, payload);

export const createNotification = (payload: NotificationProps) => axios.post('notification/create', payload);

export const deleteNotification = (_id: number) => axios.delete(`notification/delete/${_id}`, {});

export const sendPushNotification = (payload: NotificationProps) => axios.post('notification/send', payload);
