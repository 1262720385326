// Components
import * as Screens from 'screens';

export const routes = [
  {
    key: 'landing',
    path: '/',
    component: Screens.Landing,
    hasSub: false,
    exact: true
  },
  {
    key: 'login',
    path: '/cms/',
    component: Screens.Login,
    hasSub: false,
    exact: true
  },
  {
    key: 'reset-password',
    path: '/reset-password',
    component: Screens.ResetPassword,
    hasSub: false
  },
  {
    key: 'share',
    path: '/share/:path/:id',
    component: Screens.DeepLinkRedirect,
    hasSub: false
  }
];
