// Packages
import axios from 'axios';

// Utilities
import { TABLE_PAGE_SIZE } from 'shared/constants/variables';

// Interfaces
import { ArticleProps } from 'shared/types/Article';
import { ApiRequestProps } from 'shared/types/ApiRequest';

// Calls

export const getArticles = ({ lang, page }: ApiRequestProps) =>
  axios.get(`article/list?items=${TABLE_PAGE_SIZE}&page=${page}`, { headers: { 'accept-language': lang } });

export const getArticle = (_id: string) => axios.get(`article/read/${_id}`);

export const modifyArticle = (payload: ArticleProps) => {
  return axios.patch(`article/update/${payload._id}`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const createArticle = (payload: ArticleProps) => {
  return axios.post('article/create', payload, { headers: { 'Content-Type': 'multipart/form-data' } });
};

export const deleteArticle = (_id: number) => axios.delete(`article/delete/${_id}`, {});
