// Packages
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

// Components
import Card from 'shared/components/Card';
import Menu from 'shared/components/Menu';
import { Block, Check } from '@mui/icons-material';
import DataTable from 'shared/components/DataTable';
import Button from 'shared/components/Buttons/Primary';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import StatusCell from 'shared/components/DataTable/Cells/Status';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';

// Utilities
import useStyles from './styles';
import { useCommonStyles } from 'shared/assets/styles';
import { getDataLanguage } from 'redux/services/ui/slice';
import { useAppSelector, useAppThunkDispatch } from 'app/store';
import { getUsers, getUsersLoading, getUsersPagination } from 'redux/users/slice';
import { deleteUserAction, getUsersList as getUsersAction, updateUserAction } from 'redux/users/thunks';

// Interfaces
import { UserProps } from 'shared/types/User';

// Component

const Table = () => {
  // Redux
  const dispatch = useAppThunkDispatch();

  const users = useAppSelector(getUsers);
  const dataLang = useAppSelector(getDataLanguage);
  const usersPagination = useAppSelector(getUsersPagination);

  // const usersError = useAppSelector(getUsersError);
  const isUsersLoading = useAppSelector(getUsersLoading);

  // Statics
  const history = useHistory();
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const classes = { ...styles, ...commonStyles };

  const actionsAnchorEl = useRef<null | HTMLElement>(null);

  const [menuData, setMenuData] = useState<UserProps>();
  const [openActionsMenu, setOpenActionsMenu] = useState<boolean>(false);

  const genderOptions = useMemo(
    () => [
      { key: 'male', title: 'Male' },
      { key: 'female', title: 'Female' }
    ],
    []
  );

  // Callbacks
  const fetchUsers = useCallback(
    (page = 1) => {
      dispatch(getUsersAction({ lang: dataLang, page }));
    },
    [dataLang, dispatch]
  );

  const handleEditUser = useCallback((row) => history.push(`/users/form`, row), [history]);

  const handleDelete = useCallback(
    async ({ closeToast, _id }) => {
      closeToast();
      const response = await dispatch(deleteUserAction(_id));
      if (response.payload.success) fetchUsers();
    },
    [dispatch, fetchUsers]
  );

  const CloseButton = useCallback(
    (props) => <Button onClick={() => handleDelete(props)} sx={{ padding: '4px 12px' }} text="Yes" />,
    [handleDelete]
  );

  const handleActionsMenuClose = useCallback(() => {
    setOpenActionsMenu(false);
  }, []);

  const handleActionsMenuClick = useCallback((row: UserProps) => {
    setMenuData(row);
    setOpenActionsMenu(true);
  }, []);

  const handleDeleteUser = useCallback(
    ({ _id }) => {
      toast('Would you like to delete this user?', {
        autoClose: false,
        closeOnClick: true,
        position: 'top-center',
        style: { alignItems: 'center', width: 400 },
        closeButton: (props) => <CloseButton _id={_id} {...props} />
      });
    },
    [CloseButton]
  );

  const renderRoleCell = useCallback(
    ({ value }) => (
      <Typography variant="body2" className={classes.roleValue}>
        {value.charAt(0).toUpperCase() + value.slice(1)}
      </Typography>
    ),
    [classes.roleValue]
  );

  const renderRowActions = useCallback(
    ({ data }) => (
      <Box className={classes.rowActionBtns}>
        <IconButton className={classes.actionBtn} onClick={() => handleDeleteUser(data)}>
          <DeleteRoundedIcon fontSize="small" color="error" />
        </IconButton>
        <IconButton className={classes.actionBtn} onClick={() => handleEditUser(data)}>
          <EditRoundedIcon fontSize="small" color="primary" />
        </IconButton>
        <IconButton
          id="actions-button"
          aria-haspopup="true"
          ref={actionsAnchorEl}
          className={classes.actionBtn}
          aria-expanded={openActionsMenu ? 'true' : undefined}
          aria-controls={openActionsMenu ? 'actions-menu' : undefined}
          onClick={() => handleActionsMenuClick(data)}>
          <MoreVertRoundedIcon fontSize="small" color="primary" />
        </IconButton>
      </Box>
    ),
    [
      openActionsMenu,
      classes.actionBtn,
      classes.rowActionBtns,
      handleEditUser,
      handleDeleteUser,
      handleActionsMenuClick
    ]
  );

  const tableData = useMemo(
    () => _.map(users, (user) => ({ ...user, gender: _.find(genderOptions, { key: user?.gender })?.title })),
    [genderOptions, users]
  );

  const tableHeaders = useMemo(
    () => [
      { field: 'name', headerName: 'Name', flex: 1, minWidth: 130 },
      { field: 'email', headerName: 'Email', flex: 1.5, minWidth: 200 },
      { field: 'gender', headerName: 'Gender', flex: 1, minWidth: 100, valueFormatter: (p) => p.value ?? '-' },
      { field: 'country', headerName: 'Country', flex: 1, minWidth: 100, valueFormatter: (p) => p.value ?? '-' },
      {
        field: 'provider',
        headerName: 'Provider',
        flex: 1,
        minWidth: 100,
        valueFormatter: (p) => _.capitalize(p.value)
      },
      { field: 'role', headerName: 'Role', flex: 0.5, minWidth: 100, cellRenderer: renderRoleCell },
      {
        flex: 0.5,
        field: 'enabled',
        headerName: 'Status',
        minWidth: 120,
        resizable: false,
        cellRenderer: ({ value }) => <StatusCell value={value} />
      },
      {
        flex: 0.7,
        width: 140,
        field: 'actions',
        headerName: 'Actions',
        pinned: 'right',
        resizable: false,
        lockPosition: 'right',
        cellRenderer: renderRowActions
      }
    ],
    [renderRoleCell, renderRowActions]
  );

  const handleSuspendClick = useCallback(() => {
    dispatch(updateUserAction({ ...menuData, status: !menuData?.status }));
    actionsAnchorEl.current = null;
    setTimeout(() => fetchUsers(), 200);
    // toast.success(`Account ${menuData?.status ? 'Deactivated' : 'Activated'} successfully`);
  }, [dispatch, fetchUsers, menuData]);

  // const handleViewProfileClick = useCallback(() => {
  //   console.debug('[handleViewProfileClick] :: ');
  //   setActionsAnchorEl(null);
  // }, []);

  // Effects
  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Renderers Vars
  const tableProps = {
    data: tableData,
    onRefresh: fetchUsers,
    columns: tableHeaders,
    loading: isUsersLoading,
    options: {
      pagination: usersPagination
    }
  };

  const actionsMenuItems = useMemo(() => {
    const isActive = menuData?.status;

    return [
      {
        key: isActive ? 'suspend' : 'activate',
        label: isActive ? 'Suspend account' : 'Activate account',
        icon: isActive ? <Block fontSize="small" /> : <Check fontSize="small" />,
        onClick: handleSuspendClick
      }
      // {
      //   key: 'profile',
      //   label: 'View Profile',
      //   icon: <Visibility fontSize="small" />,
      //   onClick: handleViewProfileClick
      // }
    ];
  }, [handleSuspendClick, menuData?.status]);

  // Renderers
  return (
    <>
      <Box className={classes.header}>
        <Typography variant="h5">Users</Typography>
        <Button text="Add User" onClick={() => history.push('/users/form')} />
      </Box>

      <Card>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <DataTable tableProps={tableProps} />
          </Grid>
        </Grid>
      </Card>

      <Menu
        id="actions-menu"
        open={openActionsMenu}
        items={actionsMenuItems}
        anchorEl={actionsAnchorEl.current}
        onClose={handleActionsMenuClose}
        MenuListProps={{
          'aria-labelledby': 'actions-button'
        }}
      />
    </>
  );
};

export default Table;
