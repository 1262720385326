// Packages
import axios from 'axios';

// Utilities
import { UserProps } from 'shared/types/User';
import { TABLE_PAGE_SIZE } from 'shared/constants/variables';

// Interfaces
import { ApiRequestProps } from 'shared/types/ApiRequest';

// Calls

export const getUsers = ({ page, lang }: ApiRequestProps) =>
  axios.get(`user/list?items=${TABLE_PAGE_SIZE}&page=${page}`, { headers: { 'accept-language': lang } });

export const createUser = (payload: UserProps) => axios.post('user/create', payload);

export const updateUser = (payload: UserProps) => axios.patch(`user/update/${payload._id}`, payload);

export const deleteUser = (_id: number) => axios.delete(`user/delete/${_id}`, {});
