// Packages
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

// Components
import { Box, CircularProgress, Grid, Pagination, Typography } from '@mui/material';

// Utilities
import useStyles from './styles';
import { useCommonStyles } from 'shared/assets/styles';
import { getImages } from 'redux/services/config/api';
import Button from '../Buttons/Primary';

// Component
interface GalleryProps {
  path: string;
  multiple: boolean;
  onClose: () => void;
  onChange: (image: string | string[]) => void;
}

const Gallery: React.FC<GalleryProps> = ({ path, multiple, onClose, onChange }) => {
  // Statics
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const classes = { ...commonStyles, ...styles };

  const imagesPerPage = 24;
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  // Callbacks
  const fetchImages = useCallback(async () => {
    console.debug('[fetchImages] :: ', { path });

    try {
      setIsLoading(true);
      const response = await getImages({ imagePath: path });

      console.debug('[fetchImages] :: ', { response });

      if (response.data.success) {
        setImages(response.data.data);
      }
    } catch (err) {
      console.error('Error in [fetchImages] :: ', err);
    } finally {
      setIsLoading(false);
    }
  }, [path]);

  const handleImageClick = useCallback(
    (name) => {
      if (multiple) {
        const newSelectedImages = _.xor(selectedImages, [name]);
        setSelectedImages(newSelectedImages);
        onChange(newSelectedImages);
      } else {
        onChange(name);
        onClose();
      }
    },
    [multiple, onChange, onClose, selectedImages]
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Effects
  useEffect(() => {
    fetchImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Render Vars
  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = images.slice(indexOfFirstImage, indexOfLastImage);

  // Renderers
  return (
    <Box className={classes.modal}>
      <Box className={classes.modalContent}>
        {isLoading ? (
          <Box className={classes.progressContainer}>
            <CircularProgress style={{ textAlign: 'center' }} />
          </Box>
        ) : (
          <>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Select an image
            </Typography>

            <Grid container spacing={2} m={1}>
              {currentImages.map((image, index) => {
                const isSelected = _.includes(selectedImages, image?.name);

                return (
                  <Box key={index} className={classes.imageContainer}>
                    <img src={image?.url} alt="preview" className={classes.previewImage} />
                    <Button
                      text={isSelected ? 'Unselect' : 'Select'}
                      onClick={() => handleImageClick(image?.name)}
                      variant={isSelected ? 'outlined' : 'contained'}
                      className={classes.selectBtn}
                    />
                  </Box>
                );
              })}
            </Grid>

            <Pagination
              color="primary"
              page={currentPage}
              onChange={handlePageChange}
              className={classes.pagination}
              count={Math.ceil(images.length / imagesPerPage)}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

Gallery.defaultProps = {};

export default Gallery;
