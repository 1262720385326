// Packages
import axios from 'axios';

// Utilities
import { LoginProps } from './types';

// Calls

export const login = ({ email, password }: LoginProps) => axios.post('/auth/login', { email, password });

export const logout = () => axios.post('/auth/logout');

export const resetPassword = (payload) => axios.post('/auth/reset-password', payload);
