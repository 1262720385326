// Packages
import React, { useMemo } from 'react';

// Components
import { Box, Button, Divider, Grid, Typography } from '@mui/material';

// Utilities
import useStyles from './styles';
import { useIsSmall } from 'shared/utils';
import { useCommonStyles, useFormStyles } from 'shared/assets/styles';
import { APP_STORE_URL, GOOGLE_PLAY_STORE_URL } from 'shared/constants/variables';

// Component

const Landing = () => {
  // Static
  const isSmall = useIsSmall();

  // Redux

  // Statics
  const styles = useStyles();
  const formStyles = useFormStyles();
  const commonStyles = useCommonStyles();
  const classes = useMemo(() => ({ ...styles, ...formStyles, ...commonStyles }), [commonStyles, formStyles, styles]);

  // Callbacks

  // Effects

  // Renderers
  const renderInformation = () => (
    <Box className={classes.infoBox}>
      <Box className={styles.infoContainer}>
        {!isSmall && (
          <img alt="appIcon" className={classes.appIcon} src={require('../../shared/assets/images/app-icon.png')} />
        )}

        <Typography variant={isSmall ? 'h5' : 'h4'} style={{ fontWeight: 'bold', color: 'black', marginBottom: 16 }}>
          Generate Personalized Travel Itineraries Instantly with AI
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          Feeling overwhelmed with travel planning? Avandra harnesses the power of AI to craft personalized travel
          itineraries tailored to your interests, making your trips smooth and enjoyable!
        </Typography>

        <Box className={classes.storeButtons}>
          <Button onClick={() => window.open(APP_STORE_URL)} sx={{ padding: 0 }}>
            <img
              alt="applestore"
              className={classes.storeButton}
              src={require('../../shared//assets/images/appstore.png')}
            />
          </Button>

          <Button onClick={() => window.open(GOOGLE_PLAY_STORE_URL)} sx={{ padding: 0 }}>
            <img
              alt="playstore"
              className={classes.storeButton}
              src={require('../../shared//assets/images/playstore.png')}
            />
          </Button>
        </Box>

        <Divider className={classes.divider} />

        <Typography variant="body1">
          Review our{' '}
          <Typography
            component="span"
            variant="body1"
            style={{ fontWeight: 'bold', cursor: 'pointer' }}
            onClick={() => window.open('https://avandraapp.com/terms_and_conditions.html')}>
            Terms of Service
          </Typography>{' '}
          and{' '}
          <Typography
            component="span"
            variant="body1"
            style={{ fontWeight: 'bold', cursor: 'pointer' }}
            onClick={() => window.open('https://avandraapp.com/privacy_policy.html')}>
            Privacy Policy
          </Typography>{' '}
          for details.
        </Typography>

        <Typography variant="body1" style={{ marginTop: 8 }}>
          Contact us at{' '}
          <Typography
            component="span"
            variant="body1"
            style={{ fontWeight: 'bold', cursor: 'pointer' }}
            onClick={() => window.open('mailto:support@avandraapp.com')}>
            support@avandraapp.com
          </Typography>
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box className={classes.container}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={8} md={6} justifyContent="center" display="flex">
          <img
            alt="mockup-1"
            className={classes.mockup1}
            src={
              isSmall
                ? require('../../shared/assets/images/mockup-1.png')
                : require('../../shared/assets/images/mockup-2.png')
            }
          />
        </Grid>

        <Grid item xs={12} sm={8} md={6} justifyContent="center" display="flex">
          {renderInformation()}
        </Grid>

        <Grid item xs={12} sm={8} md={6} justifyContent="center" display="flex">
          {isSmall && (
            <img alt="appIcon" className={classes.appIcon} src={require('../../shared/assets/images/app-icon.png')} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Landing;
