// Packages
import axios from 'axios';

// Utilities
import { TABLE_PAGE_SIZE } from 'shared/constants/variables';

// Interfaces
import { ApiRequestProps } from 'shared/types/ApiRequest';

// Calls

export const getTrips = ({ lang, page }: ApiRequestProps) =>
  axios.get(`trip/list?items=${TABLE_PAGE_SIZE}&page=${page}`, { headers: { 'accept-language': lang } });

export const getTrip = (_id: string) => axios.get(`trip/read/${_id}`);

export const deleteTrip = (_id: string) => axios.delete(`trip/delete/${_id}`, {});
