// Packages
import { createDraftSafeSelector as createSelector, createSlice } from '@reduxjs/toolkit';

// Utilities
import { RootState } from 'app/store';
import { createNotificationAction, getNotificationsAction } from './thunks';

// Interfaces
import { NotificationInitialState } from './types';

export const initialState: NotificationInitialState = {
  data: [],
  loading: false,
  error: null
};

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    update: (state, action) => {
      state = { ...state, ...action.payload };
    },
    reset: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getNotificationsAction.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getNotificationsAction.fulfilled, (state, action) => {
      state.error = null;
      state.loading = false;
      state.data = action.payload;
    });

    builder.addCase(getNotificationsAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(createNotificationAction.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createNotificationAction.fulfilled, (state) => {
      state.error = null;
      state.loading = false;
    });

    builder.addCase(createNotificationAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  }
});

// Action creators are generated for each case reducer function
export const articleActions = notificationSlice.actions;

export default notificationSlice.reducer;

// ------------------------------------------------------------ //
// ------------------------ Selectors ------------------------- //
// ------------------------------------------------------------ //
const _articles = (state: RootState) => state.data.notifications;

export const selectNotifications = createSelector(_articles, (data) => data?.data);

export const selectNotificationsError = createSelector(_articles, (data) => data?.error);

export const selectNotificationsLoading = createSelector(_articles, (data) => data?.loading);
