// Packages
import axios from 'axios';
import { TABLE_PAGE_SIZE } from 'shared/constants/variables';
import { ApiRequestProps } from 'shared/types/ApiRequest';

// Utilities
import { TravelOptionProps } from 'shared/types/TravelOption';

// Calls

export const getTravelers = ({ lang, page }: ApiRequestProps) =>
  axios.get(`traveler/list?items=${TABLE_PAGE_SIZE}&page=${page}`, { headers: { 'accept-language': lang } });

export const modifyTraveler = (payload: TravelOptionProps) => {
  return axios.patch(`traveler/update/${payload._id}`, payload);
};

export const createTraveler = (payload: TravelOptionProps) => {
  return axios.post('traveler/create', payload);
};

export const deleteTraveler = (_id: number) => axios.delete(`traveler/delete/${_id}`, {});

export const getBudgets = ({ lang, page }: ApiRequestProps) =>
  axios.get(`budget/list?items=${TABLE_PAGE_SIZE}&page=${page}`, { headers: { 'accept-language': lang } });

export const modifyBudget = (payload: TravelOptionProps) => {
  return axios.patch(`budget/update/${payload._id}`, payload);
};

export const createBudget = (payload: TravelOptionProps) => {
  return axios.post('budget/create', payload);
};

export const deleteBudget = (_id: number) => axios.delete(`budget/delete/${_id}`, {});
